import Carousel from "../layout/carousel";
import { useCallback, useEffect, useState } from "react";
import ImageCard from "../parts/image-card";
import VideoCard from "../parts/video-card";
import photosVideosData from "./../data/photos_promotional_videos.json";
import ImageViewer from "react-simple-image-viewer";
import SimpleSlider from "../layout/simple-slider";
import { useLocation, useNavigate } from "react-router-dom";

function PhotosPromotional() {
  const photos = photosVideosData.photos;
  const videos = photosVideosData.videos;
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const sectionId = location.hash.substring(1); // Remove the '#' from the hash
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "instant" });
    } else {
      // If the section element is not found, navigate to a default route
      navigate(location.pathname);
    }
  }, [location.hash, navigate]);

  return (
    <>
      <div>
        {/******************************************** carousel slider section *********************************************/}
        <div>
          <SimpleSlider></SimpleSlider>
        </div>
        <div className="flex flex-wrap px-4" id="videos-past">
          <h1 className=" w-full text-center text-5xl pt-5 font-extrabold">
            Promotional videos
          </h1>

          {/* Four video cards */}
          {videos.map((video, index) => {
            return (
              <div className="sm:w-1/2 md:w-1/4" key={"v" + index}>
                <VideoCard
                  title={video.title}
                  embedId={video.embed_id}
                ></VideoCard>
              </div>
            );
          })}
        </div>

        <div className="flex flex-wrap bg-gray-200" id="photos">
          <h1 className=" w-full text-center text-5xl pt-5 font-extrabold">
            Recent Event Highlights
          </h1>

          {photos.map((photo, index) => {
            return (
              <div
                className="sm:w-1/2 md:w-1/4"
                onClick={() => openImageViewer(index)}
                key={"p" + index}
              >
                <ImageCard
                  link={photo.image_path}
                  title={photo.title}
                ></ImageCard>
              </div>
            );
          })}
        </div>
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={photos.map((photo, index) => photo.image_path)}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
}

export default PhotosPromotional;

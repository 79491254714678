function VideoCard({ title, embedId,width,height }) {
  const link = `https://www.youtube.com/embed/${embedId}`;
  return (
    <div className="rounded-[25px] p-4">
      <div className="mt-4 flex justify-center">
        <iframe
          src={link}
          className="rounded-[25px]"
          allow="autoplay; encrypted-media"
          allowFullScreen
          width={width} height={height}
          title="video"
        />
      </div>
      {title ? (
        <div className="mt-4">
          <p className="rounded-[25px] text-center font-bold text-xl">
            {title}
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default VideoCard;

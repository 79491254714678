import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "../../layout/carousel";
import memberShipData from "./../../data/membership.json";

function ServicesOffered() {
  const servicesOffered = memberShipData.services_offered;

  return (
    <div className="flex flex-wrap">
      {/******************************************** carousel slider section *********************************************/}
      <div className="flex flex-wrap justify-center">
        <Carousel></Carousel>
      </div>

      <div className="w-full">
        <h1 className="text-5xl ps-10 pt-5 pb-7 font-bold">
          Services Offered by the Chamber
        </h1>
      </div>
      <div className="w-full md:w-1/2 px-4 py-2">
        <ul className="list-none">
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Hand holding support and step-to-step guidance for members to
              establish their business slinkages in <b>54 African countries</b>
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Assistance in appointing liaison representatives in
              <b>10 African countries</b>
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Assistance in identifying the <b>genuine contact</b>
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Assistance in <b>Project Finance</b> (subject to the guidelines
              and project size)
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Advisory services and direction for project execution and
              management
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Offer excellent discount in delegation fee in <b>IACCI Events</b>
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Special Seating Arrangements in the <b>Event & Seminar</b>
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>Assistance in obtaining tender documents</p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              <b>Free 25 Visa</b> Recommendation Letters
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              <b>Free Attestation</b> of <b>100 export-import documents</b>{" "}
              (Certificate of Origin, invoice & packing list) by the Chamber
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              <b>25% discount</b> on Translation and Interpretation service in
              Arabic / French / Portuguese etc
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Circulation of the list of potential{" "}
              <b>importers and exporters</b> of various African countries on a
              regular basis
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Access to quality <b>business networking</b>
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Information about Exhibition / Seminars & Trade Fairs in{" "}
              <b>India & Africa</b>
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              <b>Buyer Seller Meets</b> in India as well as Africa
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Country-wise <b>digital supplements</b>
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Africa business <b>news circular</b>
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Opportunity to gain mileage by becoming a sponsor / cosponsor of
              <b>IACCI events</b>
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              Issuance of <b>Certificate of Origin</b>
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              <b>Attestation & Legalization</b> of Export / Import Documents
              (Certificate of Origin, invoice & packing list)
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              <b>Advisory services</b> for Export - Import documentation
            </p>
          </li>
          <li className="flex items-start m-5">
            <FontAwesomeIcon icon={faCheck} className="mt-1 me-5" />
            <p>
              <b>Visa Assistance</b> (Issuance and stamping)
            </p>
          </li>
        </ul>
      </div>
      <div className="w-full md:w-1/2 px-4 py-2">
        <img alt="" src="/images/homepage/country.png" />
      </div>
    </div>
  );
}

export default ServicesOffered;

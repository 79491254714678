import ImageCard from "../../parts/image-card";
import data from "../../data/countries.json";
import { Link } from "react-router-dom";
import Carousel from "../../layout/carousel";
import SimpleSlider from "../../layout/simple-slider";
function CountriesWeDeal({ onHomePage }) {
  const defaultClasses = "flex flex-wrap  pb-20";
  const countries = [];
  data.countries.forEach((country, index) => {
    if (onHomePage && index < 12) {
      countries.push(country);
    }
    if (!onHomePage) {
      countries.push(country);
    }
  });
  return (
    <div>
      {/******************************************** carousel slider section *********************************************/}
      {!onHomePage ? (
        <div>
          <SimpleSlider></SimpleSlider>
        </div>
      ) : null}

      <h1 className="text-center text-5xl pt-5 text-bold">Countries We Deal</h1>
      <div
        className={onHomePage ? defaultClasses : defaultClasses + "bg-gray-200"}
      >
        {countries.map((country, index) => (
          <div
            className={onHomePage ? "w-full md:w-1/3 lg:w-1/6 " : "w-full md:w-1/3 lg:w-1/5 "}
            key={"c" + index}
          >
            <ImageCard
              link={country.image_path}
              title={country.name}
              classes={onHomePage ? "rounded-full " : null}
            ></ImageCard>
          </div>
        ))}
      </div>
      <div>
        {onHomePage ? (
          <span className="flex justify-center pt-5">
            <button className="btn btn-primary  rounded-none font-bold text-2xl">
              <Link to="/countries-we-deal">
                <p className="px-3 py-1">Know More</p>
              </Link>
            </button>
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default CountriesWeDeal;

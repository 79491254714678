import { Link, Navigate, useNavigate } from "react-router-dom";
import InfoBar from "./infobar";
import Navbar from "./navbar";

function Header() {
    const navigate = useNavigate();

  function navToHome() {
    navigate('/');
  }
  return (
    <div className="bg-primary w-full flex flex-wrap">
      <div className="hidden md:flex w-1/4 h-full">
        <img className="cursor-pointer" src="/images/header-logo.png" onClick={navToHome} alt="logo"></img>
        {/* <Link
          to={{
            pathname: '/',
          }}
        ></Link> */}

      </div>
      <div className="w-3/4 h-full">
        {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, rerum eius sint nulla temporibus perferendis sequi libero nam soluta, quod doloremque voluptatum nemo hic repellat corrupti ipsam facere laboriosam. Veniam! */}
        <div className="py-2">
          <InfoBar></InfoBar>
        </div>
        <div className="pb-5">
          <Navbar></Navbar>
        </div>
      </div>
      {/* <div className="inline-flex bg-gray-300 lg:w-[90vw] my-2 py-2 rounded-xl">
        <Navbar></Navbar>
      </div> */}
    </div>
  );
}

export default Header;

import Carousel from "../layout/carousel";
import SimpleSlider from "../layout/simple-slider";
import locationsData from "./../data/locations.json";
function Contact() {
  const locations = locationsData.locations;
  console.log("data is ", locations);
  return (
    <div className="">
      {/******************************************** carousel slider section *********************************************/}
      <div>
        <SimpleSlider></SimpleSlider>
      </div>

      <div className="flex justify-center py-10">
        <h1 className="text-5xl font-extrabold">Our Locations</h1>
      </div>

      {/******************************************** Locations section *********************************************/}
      <div
        className="flex flex-col md:flex-row  py-10"
        id="objectives"
      >
        <div className="w-full md:w-1/2 px-4  h-auto">
          <div className="flex justify-center">
            <div role="tablist" className="tabs tabs-bordered">
              <span className="tab w-full md:w-24 lg:w-40 custom-tab">
                &nbsp;
              </span>
              {locations.map((location, index) => [
                <>
                  <input
                    type="radio"
                    name="my_tabs"
                    key={"t" + index}
                    defaultChecked={index === 1}
                    role="tab"
                    className="tab text-xl font-bold  px-14 checked:!border-b-primary hover:border-b-red-200 "
                    aria-label={location.location_name}
                  />
                </>,
                <div
                  role="tabpanel"
                  className="tab-content p-10 "
                  key={"d" + index}
                >
                  <div className="flex flex-col text-center">
                    {/* <p className="text-3xl font-bold">
                      {location.location_name}
                    </p> */}

                    <p className="text-2xl font-bold py-2">
                      {location.location_head_name}
                    </p>
                    <p className="text-xl font-bold py-1">
                      {location.location_head_profile}
                    </p>
                    <p className="text-lg py-1">
                      <i className="fa-solid fa-location-dot"></i>{" "}
                      {location.address}
                    </p>
                    <p className="text-lg py-1">
                      <i className="fa-solid fa-phone"></i>{" "}
                      {location.contact.phone}
                    </p>
                    <p className="text-lg py-1">
                      <i className="fa-solid fa-envelope"></i>{" "}
                      {location.contact.email}
                    </p>

                    {location.personal ? (
                      <>
                        <p className="text-2xl font-bold py-2">
                          {location.personal?.head_name}
                        </p>
                        {location.personal?.contact.phone ? (
                          <p className="text-lg py-1">
                            <i className="fa-solid fa-phone"></i>{" "}
                            {location.personal?.contact?.phone}
                          </p>
                        ) : null}
                        {location.personal?.contact.email ? (
                          <p className="text-lg py-1">
                            <i className="fa-solid fa-envelope"></i>
                            {location.personal?.contact?.email}
                          </p>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </div>,
              ])}
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 px-4 flex justify-center">
          <img className="max-w-lg max-h-lg" src="/images/country.png" alt="country"></img>
        </div>
      </div>
    </div>
  );
}

export default Contact;

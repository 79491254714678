import { Link } from "react-router-dom";

function ImageCard({
  link,
  title,
  showKnowMore,
  knowMoreLink,
  classes = "",
  applyDefault = true,
  knowMoreText,
  openInNewTab,
}) {
  const defaultClasses = "w-80 h-52";
  let customClasses = classes ? classes : "rounded-[25px]";
  const getDefaultClasses = () => (applyDefault ? defaultClasses : "");
  return (
    <div className="rounded-lg p-4">
      <div className="flex justify-center mt-4">
        <img
          src={link}
          alt=""
          className={`hover:cursor-pointer object-cover transition duration-300 transform scale-100  hover:scale-110 ${customClasses} ${getDefaultClasses()}`}
        />{" "}
        {/* <div class="absolute inset-0 w-full h-full "></div> */}
      </div>
      {title ? (
        <div className="flex justify-center mt-4">
          <p className="text-center font-bold text-xl">{title}</p>
        </div>
      ) : null}

      {showKnowMore ? (
        <span className="flex justify-center pt-5">
          <button className="btn btn-primary  rounded-none font-bold text-2xl">
            <Link to={knowMoreLink} target={openInNewTab ? "_blank" : ""}>
              <p className="px-3 py-1">
                {knowMoreText ? knowMoreText : "Know More"}
              </p>
            </Link>
          </button>
        </span>
      ) : null}
    </div>
  );
}

export default ImageCard;

import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SocialIcons from "../parts/social-icons";

function InfoBar() {
  return (
    <div className="flex text-white justify-between">
      {/****************************** news slot *********************/}
      {/* <div className="hidden lg:block">
        <span className="font-bold">News & Events : </span>
        <span>
        Naspers Made $1.6 Billion on the Flipkart Walmart Deal May 15, 2024
        </span>
      </div> */}
      {/****************************** contact & social media icons *********************/}
      <div className="flex ml-auto">
        {/* <span className="px-2 inline-flex items-center gap-2">
          <FontAwesomeIcon icon={faEnvelope} />
          <p>info@indoafrican.org</p>
        </span>
        <span className="px-2 inline-flex items-center gap-2">
          <FontAwesomeIcon icon={faPhone} />
          <p>+91 9892334709</p>
        </span> */}
        <SocialIcons></SocialIcons>
      </div>
    </div>
  );
}

export default InfoBar;

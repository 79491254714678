import { Link } from "react-router-dom";
import { router } from "..";
import { useState } from "react";

function Navbar() {
  let hov;
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleMouseEnter = (data, index) => {
    hov = true;
    setClickedIndex(index);
    clearTimeout();
  };

  const handleMouseLeave = (data, index) => {
    hov = false;
    setTimeout(() => {
      if (hov === false) {
        setClickedIndex(null);
      }
    }, 500);
  };

  window.routeObject = router;
  return (
    <div className="drawer text-white bg">
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        {/* Navbar */}
        <div className="navbar flex">
          <div className="flex-none lg:hidden">
            <label
              htmlFor="my-drawer-3"
              aria-label="open sidebar"
              className="btn btn-square btn-ghost"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block h-6 w-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </label>
          </div>

          {/************************ Navbar large screen begins *************************/}
          {/* <div className="flex-1 w-full h-full">
            <img className="" src="/images/header-logo.png"></img>
          </div> */}
          <div className="hidden flex-none lg:block ml-auto">
            <ul className="menu menu-horizontal font-semibold text-base">
              {/* Navbar menu content here */}

              {router.routes[0].children.map((item, index) => {
                return item.submenu ? (
                  <li key={"r-" + index}>
                    <details
                      name="menu"
                      id={"item" + index}
                      open={clickedIndex === index}
                      onMouseOver={($event) => handleMouseEnter($event, index)}
                      onMouseOut={($event) => handleMouseLeave($event, index)}
                    >
                      <summary>
                        <Link className="text-white" to={item.path}>
                          {item.name}
                        </Link>
                      </summary>
                      <ul className="bg-primary rounded-t-none pt-0 z-10 min-w-60">
                        {item.submenu.map((sub, subIndex) =>
                          sub.showAsChild ? (
                            <li key={subIndex}>
                              <Link
                                className="focus:text-white"
                                to={{
                                  pathname: item.path,
                                  hash: sub.path,
                                }}
                              >
                                {sub.title}
                              </Link>
                            </li>
                          ) : null
                        )}
                      </ul>
                    </details>
                  </li>
                ) : item.displayInNavMenu ? (
                  <li key={"r-" + index}>
                    <Link
                      className="text-white focus:text-white"
                      to={item.path}
                    >
                      {item.name}
                    </Link>
                  </li>
                ) : null;
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="drawer-side z-20">
        <label
          htmlFor="my-drawer-3"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>

        <ul className="menu bg-primary  min-h-full w-80 p-4">
          {router.routes[0].children.map((item, index) => {
            return item.submenu ? (
              <li key={"r-" + index}>
                <details
                  name="menu"
                >
                  <summary>
                    <Link className="text-white" to={item.path}>
                      {item.name}
                    </Link>
                  </summary>
                  <ul className="bg-primary rounded-t-none pt-0">
                    {item.submenu.map((child, childIndex) =>
                      child.showAsChild ? (
                        <li key={childIndex}>
                          <Link
                            className="focus:text-white"
                            to={{
                              pathname: item.path,
                              hash: child.path,
                            }}
                          >
                            {child.title}
                          </Link>
                        </li>
                      ) : null
                    )}
                  </ul>
                </details>
              </li>
            ) : item.displayInNavMenu ? (
              <li key={"r-" + index}>
                <Link className="text-white" to={item.path}>
                  {item.name}
                </Link>
              </li>
            ) : null;
          })}
        </ul>
      </div>
    </div>
  );
}

export default Navbar;

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SocialIcons() {
  return (
    <span className="px-2 hidden md:flex md:pr-5 justify-between̦” gap-4">
      <a
        href="https://www.linkedin.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="text-2xl" icon={faLinkedin} />
      </a>
      <a
        href="https://www.instagram.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="text-2xl" icon={faInstagram} />
      </a>
      <a
        href="https://www.linkedin.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="text-2xl" icon={faSquareXTwitter} />
      </a>
      <a
        href="https://www.facebook.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="text-2xl" icon={faFacebook} />
      </a>
    </span>
  );
}

export default SocialIcons;

import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Contact from "./components/contact";
import EventsExhibition from "./components/events-exhibitions";
import Home from "./components/home";
import CountriesWeDeal from "./components/home/countries-we-deal";
import EventExhibitionDetails from "./components/home/event-exhibition-details";
import PotentialSectors from "./components/home/potential-sectors";
import ServicesOffered from "./components/home/services-offered";
import Membership from "./components/membership";
import MembershipCategory from "./components/membership-category";
import PhotosPromotional from "./components/photos-promotional";
import ReputedMembers from "./components/reputed-members";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import ErrorPage from "./routes/error-page";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App></App>,
    errorElement: <ErrorPage></ErrorPage>,
    children: [
      {
        path: "/",
        name: "Home",
        children: [
          {
            path: "/",
            name: "Home",
            showAsChild: false,
            element: <Home></Home>,
          },
          {
            path: "/potential-sectors",
            displayInFooter: false,
            displayInNavMenu: true,
            showAsChild: false,
            name: "Potential Sectors",
            element: <PotentialSectors></PotentialSectors>,
          },
          {
            path: "/countries-we-deal",
            displayInFooter: false,
            displayInNavMenu: true,
            showAsChild: false,
            name: "Countries We Deal",
            element: <CountriesWeDeal></CountriesWeDeal>,
          },
        ],
        submenu: [
          {
            path: "about-us",
            showAsChild: true,
            title: "About us",
          },
          {
            path: "iacci-committee",
            showAsChild: true,
            title: "IACCI Committee ",
          },
          {
            path: "about-secretary-general",
            showAsChild: true,
            title: "About Secretary General",
          },
          {
            path: "countries-we-deal",
            showAsChild: true,
            title: "Countries we deal",
          },
          {
            path: "potential-sectors",
            showAsChild: true,
            title: "Potential Sectors",
          },
          {
            path: "why-africa",
            showAsChild: true,
            title: "Why Africa",
          },
        ],
      },
      {
        path: "/membership",
        displayInFooter: true,
        displayInNavMenu: true,
        name: "Membership",
        children: [
          {
            path: "/membership",
            displayInFooter: true,
            displayInNavMenu: true,
            name: "Membership",
            element: <Membership></Membership>,
          },
          {
            path: "/membership/services-offered",
            displayInFooter: false,
            displayInNavMenu: true,
            showAsChild: true,
            name: "Services Offered by the Chamber",
            element: <ServicesOffered></ServicesOffered>,
          },
          {
            path: "/membership/members",
            displayInFooter: false,
            displayInNavMenu: true,
            showAsChild: true,
            name: "Reputed Members",
            element: <ReputedMembers></ReputedMembers>,
          },
        ],
        submenu: [
          {
            title: "Objectives",
            showAsChild: true,
            path: "objectives",
          },
          {
            title: "Services (offered by Chamber)",
            showAsChild: true,
            path: "services-offered-by-chamber",
          },
          {
            title: "How Chamber helps in promoting trade",
            showAsChild: true,
            path: "how-chamber-helps-in-promoting-trade",
          },
          {
            title: "Membership Categories & Benefits",
            showAsChild: true,
            path: "membership-categories",
          },
          {
            title: "Bank details",
            showAsChild: true,
            path: "bank-details",
          },
          {
            title: "List of Reputed Members",
            showAsChild: true,
            path: "list-of-reputed-members",
          },
        ],
      },
      {
        path: "/membership-category",
        displayInFooter: false,
        displayInNavMenu: false,
        name: "Membership",
        element: <MembershipCategory></MembershipCategory>,
      },
      {
        path: "/events-exhibitions",
        displayInFooter: true,
        displayInNavMenu: true,
        name: "Events & Exhibitions",
        element: <EventsExhibition></EventsExhibition>,
        submenu: [
          {
            path: "past-events",
            showAsChild: true,
            title: "Past Events",
          },
          {
            path: "past-webinars",
            showAsChild: true,
            title: "Past Webinars ",
          },
        ],
      },
      {
        path: "/events-exhibitions/details/:id",
        displayInFooter: false,
        displayInNavMenu: false,
        name: "Event Details",
        element: <EventExhibitionDetails></EventExhibitionDetails>,
      },
      {
        path: "/photos-promotional-video",
        displayInFooter: true,
        displayInNavMenu: true,
        name: "Photos & Promotional Video",
        element: <PhotosPromotional></PhotosPromotional>,
        submenu: [
          {
            path: "photos",
            showAsChild: true,
            title: "Recent Event Highlights",
          },
          {
            path: "videos-past",
            showAsChild: true,
            title: "Promotional videos",
          },
        ],
      },
      {
        path: "/contact",
        displayInFooter: true,
        displayInNavMenu: true,
        name: "Contact",
        element: <Contact></Contact>,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useParams } from "react-router-dom";
import eventsData from "./../../data/events_webinars.json";

function EventExhibitionDetails() {
  let { id } = useParams();
  let selectedEvent;
  eventsData.events.forEach((event) => {
    console.log("each event is ", event, event.id);
    if (event.id === parseInt(id)) {
      selectedEvent = event;
    }
  });
  console.log("Event Exhibition Details", selectedEvent);
  return (
    <div>
      <div
        dangerouslySetInnerHTML={{ __html: selectedEvent.content_html }}
      ></div>
    </div>
  );
}

export default EventExhibitionDetails;

import { Outlet, ScrollRestoration } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import Footer from "./layout/footer";
import Header from "./layout/header";

function App() {
  return (
    <>
      {/* <div className="flex fixed top-0 z-30"> */}
      <div className="flex">
        <Header></Header>
      </div>
      {/* <div className="pt-32"> */}
      <div>
        <ScrollRestoration />
        <Outlet />
      </div>
      <div className="w-full bg-red-800 bottom-0">
        <Footer></Footer>
      </div>
    </>
  );
}

export default App;

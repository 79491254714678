import { useLocation, useNavigate } from "react-router-dom";
import Carousel from "../layout/carousel";
import SimpleSlider from "../layout/simple-slider";
import ImageCard from "../parts/image-card";
import VideoCard from "../parts/video-card";
import eventsData from "./../data/events_webinars.json";
import { useEffect } from "react";
function EventsExhibition() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const sectionId = location.hash.substring(1); // Remove the '#' from the hash
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "instant" });
    } else {
      // If the section element is not found, navigate to a default route
      navigate(location.pathname);
    }
  }, [location.hash, navigate]);


  console.log("Events Exhibition", eventsData);
  const events = eventsData.events;
  const webinars = eventsData.webinars;
  return (
    <>
      {/******************************************** carousel slider section *********************************************/}
      <div>
        <SimpleSlider></SimpleSlider>
      </div>
      <div id="past-events">
        <h1 className="text-center text-5xl pt-5 font-extrabold">
          Past Events
        </h1>
        <div className="flex flex-wrap">
          {events.map((ev, index) => (
            <div className="sm:w-1/2 md:w-1/4" key={"r" + index}>
              <ImageCard
                link={ev.image_path}
                title={ev.title}
              ></ImageCard>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gray-200" id="past-webinars">
        <h1 className="text-center text-5xl pt-5 font-extrabold">
          Past Webinars
        </h1>
        <div className="flex flex-wrap">
          {/* Four video cards */}
          {webinars.map((wb, index) => (
            <div className="sm:w-1/2 md:w-1/4" key={"w" + index}>
              <VideoCard title={wb.title} embedId={wb.embed_id}></VideoCard>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default EventsExhibition;

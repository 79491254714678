import { Link } from "react-router-dom";
import { router } from "..";
import SocialIcons from "../parts/social-icons";
import ImageCard from "../parts/image-card";

function Footer() {
  return (
    <footer className="flex flex-wrap text-white pt-10">
      {/************************************ logo area *************************************/}
      <div className="w-full md:w-1/2 lg:w-1/4 lg:p-4 ">
        <div className="flex flex-wrap justify-center">
          <div>
            <ImageCard
              link="/images/footer-logo.png"
              applyDefault={false}
              classes={"w-full h-full"}
              title="Indian African Chamber of Commerce and Industry"
            ></ImageCard>
          </div>
          <div className="text-3xl">
            <SocialIcons></SocialIcons>
          </div>
        </div>
      </div>

      {/************************************ Links area *************************************/}
      <div className="w-full md:w-1/2 lg:w-1/4 lg:p-4">
        <h2 className="text-3xl pb-4">Quick Links</h2>
        <ul className="text-lg">
          <li className="cursor-pointer py-2">
            <Link to={`/`}>Home</Link>
          </li>
          {router.routes[0].children.map((item, index) => {
            return item.displayInFooter ? (
              <li key={"r-" + index} className="py-2">
                <Link to={item.path}>{item.name}</Link>
              </li>
            ) : null;
          })}
        </ul>
      </div>

      {/************************************ Contact area *************************************/}

      <div className="w-full md:w-1/2 lg:w-1/2 lg:p-4 text-lg">
        <h2 className="text-3xl">Contact Details</h2>
        <div className="pt-5">
          <p>
            <i className="fa-solid fa-location-dot"></i> 406, 4th Floor Maker Chamber -V, Nariman Point, Mumbai - 400021
          </p>
        </div>
        <div className="pt-5">
          <p>
            <i className="fa-solid fa-phone"></i> Tel : +91 9987358914 / 9987708915
            / 9987328917{" "}
          </p>
        </div>

        <div className="pt-5">
          <p>
            <i className="fa-solid fa-envelope"></i> Email : info.iacci@gmail.com
          </p>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/4 lg:p-4 ">
        {/************************************ Site Map area *************************************/}

        {/* <h2 className="text-3xl">Site Map</h2> */}
        <div>{/* Map will be displayed here   */}</div>
      </div>
      {/************************************ Site Map area *************************************/}
      <div className="w-full bg-gray-200 text-gray-800 ps-20 py-5">
        <p className="">
          © Copyright - Indo African Of Commerce & Industry. All Right Reserved
        </p>
      </div>
    </footer>
  );
}

export default Footer;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carousel from "../layout/carousel";
import CountriesWeDeal from "./home/countries-we-deal";
import PotentialSectors from "./home/potential-sectors";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import VideoCard from "../parts/video-card";
import SimpleSlider from "../layout/simple-slider";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Home() {

const location = useLocation();
const navigate = useNavigate();

useEffect(() => {
  const sectionId = location.hash.substring(1); // Remove the '#' from the hash
  const sectionElement = document.getElementById(sectionId);

  if (sectionElement) {
    sectionElement.scrollIntoView({ behavior: "instant" });
  } else {
    // If the section element is not found, navigate to a default route
    navigate(location.pathname);
  }
}, [location.hash, navigate]);


  return (
    <div className="flex flex-wrap">
      {/******************************************** carousel slider section *********************************************/}
      {/* <div className="w-full flex flex-wrap justify-center">
        <Carousel></Carousel>
      </div> */}
      {/******************************************** carousel slider section *********************************************/}
      <div className="w-full">
        <SimpleSlider></SimpleSlider> 
        </div>
      {/******************************************** about us section *********************************************/}
      <div className="px-10 flex flex-wrap  justify-center" id="about-us">
        <div className="w-full md:w-1/2 p-4">
          <h1 className="text-5xl font-bold">About us</h1>
          <div>
            <p className="text-md my-5 text-gray-700">
              The Indo-African Chamber has been servin the industry for the last
              40 years. The chamber plays the role of a catalyst in
              strenthening, promoting the 4 century old histroical relationship
              between India and Africa.
            </p>

            <p className="text-md my-5 text-gray-700">
              We work to create and sustain an environment conducive to the
              growth of industry between India and <b> 54 African countries,</b>
              catering to government and Indusries through pat braking
              intiatives of which <b>"I For Afrika"</b> and
              <b>Africa Trade Summit</b>
              are the best examples.
            </p>

            <p className="text-md my-5 text-gray-700">
              <b>IACCI</b> is a non profit industry managed, apex chamber,
              playing a pivotal role in India's development process. The Chamber
              is one of the Premier Organisation with a direct access to more
              than 80,000 MSME companies from both the public and private sector
              with an indirect membership of over 5,00,000 companies linked 23
              states adn regional associations
            </p>
            <p className="text-md my-5 text-gray-700">
              The chamber constantly strives to identify new areas of mutual
              co-operations and ensure smooth flow of trade, investment, joint
              ventures, and technology transfer with skill development.
            </p>
            <p className="text-md my-5 text-gray-700">
              The vision of the Chamber is "Make Africa Your Partner"
            </p>
          </div>
        </div>

        <div className="w-full md:w-1/2 p-4">
          <img alt="" src="/images/homepage/country.png" />
        </div>
      </div>

      {/******************************************** Indo african tree section *********************************************/}

      <div className="w-full p-4" id="iacci-committee">
        <h1 className="text-5xl text-center font-bold">
          IACCI List of Committee Members
        </h1>
        <img
          className="image-full w-full"
          alt=""
          src="/images/homepage/hierarchy.png"
        />
        <img
          className="image-full w-full"
          alt=""
          src="/images/homepage/gen.png"
        />
      </div>
      {/******************************************** About Secretary General *********************************************/}
      <div
        className="px-10 flex flex-wrap justify-center"
        id="about-secretary-general"
      >
        <div className="w-full md:w-1/2 p-4">
          <h1 className="text-5xl font-bold">About Secretary General</h1>
          <p className="text-md my-5 text-gray-700">
            Sunanda Rajendran is the Founder Director and the Secretary general
            appointed in the year 1992 of the Indo African Chamber of Commerce.
            Even before that she is working as Executive Director of the Indo
            Arab Chamber of Commerce & Industries since its inception 1983. Both
            the institutions are today the Leading Premier Chamber of Commerce
            in India.
          </p>
          <p className="text-md my-5 font-bold text-gray-700">
            Born on September 5th in Mumbai, Maharashtra.
          </p>

          <p className="text-md text-gray-700 my-5">
            She has been passionate for promoting both the Arab & African
            Sector. Total 78 Countries (mainly 24 Arab & 54 Africa) She has been
            awarded “Justice of Peace” by the Yemen Govt in the Year 1989 where
            she played very important role during unification of Yemen.
          </p>

          <p className="text-md text-gray-700 my-5">
            She had settled 2089 disputes between the Indian and the Arab
            companies till date amicably without going to the court of Law
            successfully. She has extensively traveled to almost 62 Countries.
            With vast 32 Years of Practical experience in this industry.
          </p>

          <p className="text-md text-gray-700 my-5">
            She is a person of great respect and high repotution. She is born
            and brought up in south Mumbai. Graduated from HR college in Mumbai,
            Further persuaded her studies with Mumbai University. Thereafter she
            acquired special studies in Law, Arbitration, Export/Import,
            Finance, Banking & Economics.
          </p>
        </div>
        <div className="w-full md:w-1/2 p-4">
          <img
            alt=""
            className="w-full h-full rounded-3xl"
            src="/images/homepage/AboutSecretary.png"
          />
        </div>
      </div>

      {/******************************************** countries we deal *********************************************/}

      <div className="w-full p-4" id="countries-we-deal">
        <CountriesWeDeal onHomePage={true}></CountriesWeDeal>
      </div>

      {/******************************************** potential sectors *********************************************/}

      <div className="w-full p-4" id="potential-sectors">
        <PotentialSectors onHomePage={true}></PotentialSectors>
      </div>

      {/******************************************** Why Africa ? *********************************************/}
      <div className="px-10 flex flex-wrap justify-center" id="why-africa">
        <div className="w-full md:w-1/2 h-fit p-4">
          <img alt="" src="/images/homepage/africa.png" />
        </div>
        <div className="w-full md:w-1/2 p-4">
          <h1 className="text-5xl font-bold">Why Africa ?</h1>
          <ul>
            <li className="flex items-start m-5 pt-10">
              <FontAwesomeIcon
                icon={faCheck}
                className="mt-1 me-5"
              ></FontAwesomeIcon>
              AFRICA IS YOUNG AND BIGGEST BUSINESS OPPORTUNITY FOR INDIA
            </li>

            <li className="flex items-start m-5">
              <FontAwesomeIcon
                icon={faCheck}
                className="mt-1 me-5"
              ></FontAwesomeIcon>
              Indian Ocean is the bridge to Africa
            </li>

            <li className="flex items-start m-5">
              <FontAwesomeIcon
                icon={faCheck}
                className="mt-1 me-5"
              ></FontAwesomeIcon>
              The growing Indo-Africa partnership, led by the private sector,
              has scripted many success stories
            </li>

            <li className="flex items-start m-5">
              <FontAwesomeIcon
                icon={faCheck}
                className="mt-1 me-5"
              ></FontAwesomeIcon>
              "Africa's growth is of immense value not only to the african
              economies but also to the other global economies, many of which
              are still grappling with the impact of the global econmic slowdown
              and the Euro zone crises
            </li>
            <li className="flex items-start m-5">
              <FontAwesomeIcon
                icon={faCheck}
                className="mt-1 me-5"
              ></FontAwesomeIcon>
              India - Africa bilateral trade growing steadily, year on year and
              the trade volume is touching $55.9bn in 2020-21
            </li>

            <li className="flex items-start m-5">
              <FontAwesomeIcon
                icon={faCheck}
                className="mt-1 me-5"
              ></FontAwesomeIcon>
              India's cumulative investments in the African region has now
              reached to $54billion
            </li>
            <li className="flex items-start m-5">
              <FontAwesomeIcon
                icon={faCheck}
                className="mt-1 me-5"
              ></FontAwesomeIcon>
              India is the 5th largest Investor in Africa
            </li>
          </ul>
        </div>
      </div>

      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="col-span-1">
            <div className="aspect-w-16 aspect-h-9">
              <VideoCard
                embedId={"uohJBSvF5N0"}
                width={560}
                height={315}
              ></VideoCard>
            </div>
          </div>

          <div className="col-span-1">
            <div className="aspect-w-16 aspect-h-9">
              <VideoCard
                embedId={"uVmNmOD8U0g"}
                width={560}
                height={315}
              ></VideoCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;

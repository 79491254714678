import { Link, ScrollRestoration } from "react-router-dom";
import Carousel from "../../layout/carousel";
import ImageCard from "../../parts/image-card";
import data from "./../../data/potential_sectors.json";
import SimpleSlider from "../../layout/simple-slider";

function PotentialSectors({ onHomePage }) {
  const defaultClasses = "flex flex-wrap  pb-20";
  const sectors = [];
  data.sectors.forEach((sector, index) => {
    if (onHomePage && index < 8) {
      sectors.push(sector);
    }
    if (!onHomePage) {
      sectors.push(sector);
    }
  });
  return (
    <div className={onHomePage ? "bg-gray-200" : ""}>
      {/******************************************** carousel slider section *********************************************/}
      {!onHomePage ? (
        <div>
          <SimpleSlider></SimpleSlider>
        </div>
      ) : null}
      <h1 className="text-center text-5xl pt-5 text-bold">
        Potential Sectors in Africa
      </h1>

      <div className="grid grid-col-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mx-auto px-4">
        {sectors.map((sector, index) => (
          <ImageCard
            key={"c" + index}
            link={sector.image_path}
            title={sector.name}
          ></ImageCard>
        ))}
      </div>
      <div>
        {onHomePage ? (
          <span className="flex justify-center py-10">
            <button className="btn btn-primary  rounded-none font-bold text-2xl">
              <Link to="/potential-sectors">
                <p className="px-3 py-1">Know More</p>
              </Link>
            </button>
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default PotentialSectors;


function IconCard({ icon, text }) {
  const defaultClasses = "fa-4x ";
  return (
    <div className="flex flex-col justify-center items-center">
      <div className=" w-40 h-40 rounded-full flex flex-wrap items-center justify-center font-bold text-primary  bg-red-50">
        <i className={defaultClasses + icon}></i>
      </div>
      <p className="text-center text-lg py-5">{text}</p>
    </div>
  );
}

export default IconCard;

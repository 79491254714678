import { Link } from "react-router-dom";
import ImageCard from "../parts/image-card";
import data from "./../data/reputed_members.json";
import Carousel from "../layout/carousel";
import SimpleSlider from "../layout/simple-slider";

function ReputedMembers({ onOtherPage }) {
  // const members = data.members;
  const members = [];
  data.members.forEach((member, index) => {
    if (onOtherPage && index < 10) {
      members.push(member);
    }
    if (!onOtherPage) {
      members.push(member);
    }
  });

  return (
    <div className={onOtherPage ? "bg-gray-200" : ""}>
      {/******************************************** carousel slider section *********************************************/}
      {!onOtherPage ? (
        <div>
          <SimpleSlider></SimpleSlider>
        </div>
      ) : null}
      <h1 className="text-center text-5xl pt-5 text-bold">
        List of Reputed Members
      </h1>

      <div className="flex flex-wrap justify-center">
        {members.map((member, index) => (
          <div className="sm:w-1/5" key={"i" + index}>
            <ImageCard
              classes="w-full h-full"
              link={member.image_path}
            ></ImageCard>
          </div>
        ))}
      </div>

      <div>
        {onOtherPage ? (
          <span className="flex justify-center py-10">
            <button className="btn btn-primary  rounded-none font-bold text-2xl">
              <Link to="/membership/members">
                <p className="px-3 py-1">Know More</p>
              </Link>
            </button>
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default ReputedMembers;


import { useNavigate } from "react-router-dom";
import { router } from "..";
import sliderImage from "./../data/slider.json";

function Carousel() {
    const navigate = useNavigate();
  function getNextRef(index) {
    const images = sliderImage.images;
    const nextItem = index === (images.length -1) ? 0 : index + 1;
    return "#item" + nextItem;
  }
  function getPrevRef(index) {
    const images = sliderImage.images;
    const prevItem =
      index === 0 ? images.length - 1 : index - 1;
    return "#item" + prevItem;
  }

  function slideAuto(){
    setInterval(() =>{
      navigate(getNextRef())  
    }, 100);
  }
    // function handleClick(event, index,action) {
    //   event.preventDefault(); // Prevent the default behavior of the anchor tag

    //   const images = sliderImage.images;
    //   const selectedItem = action === 'next'  ? index === images.length - 1 ? 0 : index + 1 : index === 0 ? images.length - 1 : index - 1;

    //   // Manually navigate to the next item
    //   let itemRef = document.getElementById("item" + selectedItem);
    //   if (itemRef) {
    //     itemRef.scrollIntoView({ behavior: "smooth" });
    //   }
    // }

  return (
    <>
      <div className="carousel w-full min-h-80 md:min-h-48 md:w-full rounded-xl">
        {sliderImage.images.map((image, index) => (
          <div id={"item" + index} className="carousel-item relative w-full" key={index}>
            <img alt="" src={image.path} className="w-full" />
            <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform justify-between">
              <a href={getPrevRef(index)} 
              // onClick={(e) => handleClick(e, index,'next')}
              className="btn btn-circle">
                ❮
              </a>
              <a href={getNextRef(index)} 
              // onClick={(e) => handleClick(e, index,'prev')}
              className="btn btn-circle">
                ❯
              </a>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="flex w-full justify-center gap-2 py-2">
        {sliderImage.images.map((image, index) => (
          <a key={index} href={"#item" + index} className="btn btn-xs">
            {index + 1}
          </a>
        ))}
      </div> */}
    </>
  );
}

export default Carousel;

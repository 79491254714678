import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSearchParams } from "react-router-dom";
import Carousel from "../layout/carousel";
import ImageCard from "../parts/image-card";
import memberShipData from "./../data/membership.json";
import SimpleSlider from "../layout/simple-slider";


function MembershipCategory() {
    let [searchParams] = useSearchParams();
    const category = searchParams.get('catId');
    const selectedCategory = memberShipData.categories.find(c => c.id === parseInt(category));
    
    if(selectedCategory === undefined) {
      // navigate to back page
      return null;
    }
  return (
    <div>
      {/******************************************** carousel slider section *********************************************/}
      <div>
        <SimpleSlider></SimpleSlider>
      </div>
      <div className="w-full text-center">
        <h1 className="text-5xl font-bold py-2">{selectedCategory.name}</h1>
        <h2 className="text-4xl font-bold text-primary py-2">
          Rs {selectedCategory.price} + GST + Admission Fees
        </h2>
      </div>

      <div className="flex flex-col md:flex-row justify-center py-10">
        <div className="w-full md:w-1/2 px-4  h-auto">
          <h3 className="font-bold text-2xl ps-5">Deliverables</h3>
          <ul className="list-none">
            {
              selectedCategory.deliverables.map((deliverable, index) => (
                <li key={index} className="flex items-start m-5">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="mt-1 me-5"
                  ></FontAwesomeIcon>
                  {deliverable}
                </li>
              ))
            }
          </ul>
        </div>

        <div className="w-full md:w-1/2 px-4">
          <ImageCard
            link={selectedCategory.image_path}
            showKnowMore="true"
            knowMoreText={"Download Form"}
            openInNewTab={true}
            classes="h-fit w-full"
            knowMoreLink={selectedCategory.form_path}
          ></ImageCard>
        </div>
      </div>
    </div>
  );
}

export default MembershipCategory;

import React from "react";
import Slider from "react-slick";
import sliderImage from "../data/slider.json";

export default function SimpleSlider() {
  var settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    arrows: true,
    fade: true,
    infinite: true,
    adaptiveHeight:true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Slider {...settings}>
      {sliderImage.images.map((image, index) => (
        <div key="index">
          <img alt="" src={image.path} className="w-full" />
        </div>
      ))}
    </Slider>
  );
}

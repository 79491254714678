import { faCheck, faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SimpleSlider from "../layout/simple-slider";
import IconCard from "../parts/icon-card";
import memberShipData from "./../data/membership.json";
import ReputedMembers from "./reputed-members";
function Membership() {
  const objectives = memberShipData.objectives;
  const servicesOffered = memberShipData.services_offered;
  const howChamberHelps = memberShipData.how_chember_helps;
  const categories = memberShipData.categories;

const location = useLocation();
const navigate = useNavigate();

useEffect(() => {
  const sectionId = location.hash.substring(1); // Remove the '#' from the hash
  const sectionElement = document.getElementById(sectionId);

  if (sectionElement) {
    sectionElement.scrollIntoView({ behavior: "instant" });
  } else {
    // If the section element is not found, navigate to a default route
    navigate(location.pathname);
  }
}, [location.hash, navigate]);


  return (
    <div>
      {/******************************************** carousel slider section *********************************************/}
      <div >
        <SimpleSlider></SimpleSlider>
      </div>

      {/******************************************** Objectives section *********************************************/}
      <div
        className="flex flex-col md:flex-row justify-center py-10"
        id="objectives"
      >
        <div className="w-full md:w-1/2 px-4  h-auto">
          <div className="w-full text-center">
            <h1 className="text-5xl font-bold py-2">Our Objectives</h1>
          </div>

          <ul className="list-none">
            {objectives.items.map((objective, index) => (
              <li key={index} className="flex items-start m-5">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="mt-1 me-5"
                ></FontAwesomeIcon>
                {objective}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-full md:w-1/2 px-4">
          <img src={objectives.image_path} alt="country"></img>
        </div>
      </div>

      {/******************************************** Services Offered by the Chamber section *********************************************/}
      <div
        className="flex flex-col md:flex-row justify-center py-10 text-white bg-primary"
        id="services-offered-by-chamber"
      >
        <div className="w-full md:w-1/2 px-4">
          <img src={servicesOffered.image_path} alt="country"></img>
        </div>

        <div className="w-full md:w-1/2 px-4  h-auto">
          <div className="w-full text-center">
            <h1 className="text-5xl font-bold py-2">
              Services Offered by the Chamber
            </h1>
          </div>

          <ul className="list-none text-lg">
            {servicesOffered.items.map((service, index) => (
              <li key={index} className="flex items-start m-5">
                <FontAwesomeIcon
                  icon={faCheck}
                  className="mt-1 me-5"
                ></FontAwesomeIcon>
                {service}
              </li>
            ))}
          </ul>
          <button className="btn btn-primary bg-white hover:bg-gray-200 ms-14 rounded-none font-bold text-2xl">
            <Link to="/membership/services-offered">
              <p className="px-3 py-1 text-primary">Read More</p>
            </Link>
          </button>
        </div>
      </div>

      {/**************************** How Chamber Helps in Promoting Indo-African Trade *********************************************/}
      <div
        className="flex flex-wrap justify-center py-10 "
        id="how-chamber-helps-in-promoting-trade"
      >
        <h1 className="text-4xl text-center font-bold py-5 w-2/5">
          How Chamber Helps in Promoting Indo-African Trade
        </h1>
        {/* <div className="w-full flex flex-wrap justify-center gap-8 w-full px-10"> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-7 pt-8 grid-rows-auto sm:grid-rows-none w-[90%]">
          {howChamberHelps.map(({ icon, title }, index) => {
            return <IconCard key={index} icon={icon} text={title}></IconCard>;
          })}
        </div>
        <img
          className="image-full w-full"
          alt=""
          src="/images/homepage/gen.png"
        />
      </div>

      {/**************************** Membership Categories & Benefits *********************************************/}
      <div
        className="flex flex-wrap justify-center py-10 "
        id="membership-categories"
      >
        <h1 className="text-5xl text-center font-bold py-2">
          Membership Categories & Benefits
        </h1>
        <div className="w-full flex flex-wrap gap-8 justify-center">
          {categories.map((category, index) => (
            <div
              className="card bg-base-100 sm:w-full md:w-80  shadow-xl"
              key={index}
            >
              <div className="card-body items-center text-center">
                <h2 className="card-title text-xl">{category.name}</h2>
                <div>
                  <h1 className="card-title font-extrabold text-4xl text-primary">
                    <FontAwesomeIcon
                      icon={faIndianRupeeSign}
                      className=""
                    ></FontAwesomeIcon>
                    {category.price}
                  </h1>
                </div>
                <p className="font-bold text-lg"> + GST + Admission Fees</p>
                <figure className="px-10 pt-10">
                  <img
                    src="/images/membership/membership-form.png"
                    alt="Shoes"
                    className="rounded-xl"
                  />
                </figure>

                <div className="card-actions justify-center">
                  <button className="btn my-2 bg-gray-400 rounded-none font-bold text-2xl">
                    <Link to={"/membership-category?catId=" + category.id}>
                      <p className="px-3 py-1 text-white">View More</p>
                    </Link>
                  </button>
                  <button className="btn my-2 bg-primary rounded-none font-bold text-2xl">
                    <Link to={category.form_path} target="_blank">
                      <p className="px-3 py-1 text-white">Download Form</p>
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          ))}

          <div
            className="flex flex-wrap flex-col justify-center py-5"
            id="bank-details"
          >
            <h3 className="text-2xl text-center font-bold pb-5">
              Bank Details
            </h3>
            <p className="text-lg text-center font-semibold">
              Account Holder Name - Indo-African Chamber of Commerce & Industry
            </p>
            <p className="text-lg text-center font-semibold">
              Account Number - 698705600396
            </p>
            <p className="text-lg text-center font-semibold">
              IFSC - ICIC0006987 | Account Type - Current |  Bank - ICICI Bank Ltd.
            </p>
          </div>
        </div>
      </div>

      {/**************************** List of Few Reputed Members *********************************************/}
      <div className="w-full" id="list-of-reputed-members">
        <ReputedMembers onOtherPage={true}></ReputedMembers>
      </div>
    </div>
  );
}

export default Membership;
